import LoveLetter from './components/LoveLetter/LoveLetter';
import './App.css';

function App() {
  return (
    <div className="App">
      <LoveLetter />
    </div>
  );
}

export default App;
