import React, { useState, useRef } from 'react';
import './LoveLetter.css';
import audioFile from './ya_no_vivo_por_vivir.m4a';
import michis from './michis.gif';

const LoveLetter = () => {
  // var emoji = "💖💗💓💞💕❤️😍😘😻😽🩷🧡💛💚💙🤍🩶🖤🤎💜🩵❤️‍🔥❤️‍🩹❣️💘";
  var emoji = ['💖', '💗','💘','😍','😻','🧡','💝'];
  var container = document.getElementById('animate');
  var emoji_container = document.getElementById('all');

  var circles = [];

  for(var i=0; i<emoji.length; i++){
    addCircle(i * 150, [10 + 0, 300], emoji[Math.floor(Math.random() * emoji.length)]);
    addCircle(i * 150, [10 + 0, -300], emoji[Math.floor(Math.random() * emoji.length)]);
    addCircle(i * 150, [10 - 200, -300], emoji[Math.floor(Math.random() * emoji.length)]);
    addCircle(i * 150, [10 + 200, 300], emoji[Math.floor(Math.random() * emoji.length)]);
    addCircle(i * 150, [10 - 400, -300], emoji[Math.floor(Math.random() * emoji.length)]);
    addCircle(i * 150, [10 + 400, 300], emoji[Math.floor(Math.random() * emoji.length)]);
    addCircle(i * 150, [10 - 600, -300], emoji[Math.floor(Math.random() * emoji.length)]);
    addCircle(i * 150, [10 + 600, 300], emoji[Math.floor(Math.random() * emoji.length)]);
  }

  function addCircle(delay, range, color) {
    setTimeout(function() {
      var c = new _circle(range[0] + Math.random() * range[1], 80 + Math.random() * 4, color, {
        x: -0.15 + Math.random() * 0.3,
        y: 1 + Math.random() * 1
      }, range);
      circles.push(c);
    }, delay);
  }

  function _circle(x, y, c, v, range) {
    var _this = this;
    this.x = x;
    this.y = y;
    this.color = c;
    this.v = v;
    this.range = range;
    this.element = document.createElement('span');
    /*this.element.style.display = 'block';*/
    this.element.style.opacity = 0;
    this.element.style.position = 'absolute';
    this.element.style.fontSize = '26px';
    this.element.style.color = 'hsl('+(Math.random()*360|0)+',80%,50%)';
    this.element.innerHTML = c;
    container.appendChild(this.element);

    this.update = function() {
      if (_this.y > 800) {
        _this.y = 80 + Math.random() * 4;
        _this.x = _this.range[0] + Math.random() * _this.range[1];
      }
      _this.y += _this.v.y;
      _this.x += _this.v.x;
      this.element.style.opacity = 1;
      this.element.style.transform = 'translate3d(' + _this.x + 'px, ' + _this.y + 'px, 0px)';
      this.element.style.webkitTransform = 'translate3d(' + _this.x + 'px, ' + _this.y + 'px, 0px)';
      this.element.style.mozTransform = 'translate3d(' + _this.x + 'px, ' + _this.y + 'px, 0px)';
    };
  }

  function animate() {
    for (var i in circles) {
      circles[i].update();
    }
    requestAnimationFrame(animate);
  }
  const [isOpen, setIsOpen] = useState(false);
  const [isFullSize, setIsFullSize] = useState(false);
  const audioRef = useRef(null);

  const handleOpenLetter = () => {
    setIsOpen(true);
    setTimeout(() => {
      setIsFullSize(true);
      emoji_container.hidden = false;
      animate();
      // Ensuring audio play is directly a result of this user interaction
      if (audioRef.current) {
        audioRef.current.play()
          .then(() => console.log("Playback succeeded"))
          .catch(e => console.error("Playback failed:", e));
      }
    }, 800);
  };

  const handleCloseLetter = () => {
    // setIsFullSize(false);
    // setTimeout(() => {
    //   if (audioRef.current) {
    //     audioRef.current.pause();
    //   }
    //   setIsOpen(false);
    // }, 800);
  };

  return (
    <div className={`envelope ${isOpen ? 'open' : ''}`} onClick={!isFullSize ? handleOpenLetter : handleCloseLetter}>
      <div className="flap" hidden={isOpen}></div>
      <div className="body" hidden={isOpen}></div>
      <div className={`letter ${isFullSize ? 'fullSize' : ''}`}>
        <h2>¡Feliz Cumpleaños mi Are Gatito!</h2>
        <p>Te dedico esta canción, porque desde que estas en mi vida... <br/><b>¡Ya no vivo por vivir!</b></p>
        <br/>
        {/* insertamos imagen de michis */}
        <img src={michis} alt="michis"/>
      </div>
      <audio ref={audioRef} src={audioFile} onError={(e) => console.error('Audio error:', e.message)} />
    </div>

  );
};

export default LoveLetter;
